import loadable from '@loadable/component'
import { Grid, Button, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useCallback } from 'react'
import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { Txt } from '../components/Txt'

const Map = loadable(() => import('../components/Map'), { ssr: false })

export const Head = () => (
    <SEO
        title="安い結婚指輪、婚約指輪のご試着はミスプラチナ渋谷ショールームへ。1～3万円台のデザインを多数ご用意！"
        description="安い、安心、高品質の結婚指輪のご試着はミスプラチナ渋谷ショールームへ。1万円～3万円台の結婚指輪や、6万円台のダイヤ入り婚約指輪も多数ご用意。"
        page="salon"
    />
)

const SalonPage: React.FC = () => {
    const muiTheme = useTheme()
    const goToReservation = useCallback(() => {
        window.gtag !== undefined && window.gtag('event', 'go_to_reservation')
    }, [])
    return (
        <Layout breadcrumbs={[{ name: 'ミスプラチナ渋谷ショールーム' }]}>
            <Grid container spacing={0}>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12} component="main">
                    <H1>
                        <Txt size="l">試着・見学サロン</Txt>
                    </H1>
                    <H2>
                        <Txt>お車でご来社のお客様</Txt>
                        <Txt>駐車場代1,500円分キャッシュバック！</Txt>
                    </H2>
                    <StaticImage src="../images/salon/salon.jpg" alt="" />
                    <Desc>
                        <CampaignTxt size="s">
                            ご来社時にお申込みの場合のみ適用となります。
                        </CampaignTxt>
                        <CampaignTxt size="s" bold>
                            ※ShowRoomはご予約制となっております。（当日予約受付
                            11:00まで）
                        </CampaignTxt>
                    </Desc>
                    <Table>
                        <Row>
                            <H>
                                <Txt size="s" bold>
                                    住所
                                </Txt>
                            </H>
                            <D>
                                <Txt size="s">〒150-0001</Txt>
                                <Txt size="s">東京都渋谷区神宮前3−16−16−1F</Txt>
                                <DTxt size="s" theme={muiTheme}>
                                    ※表参道駅徒歩８分/JR原宿駅徒歩10分
                                </DTxt>
                            </D>
                        </Row>
                        <Row>
                            <H>
                                <Txt size="s" bold>
                                    営業時間
                                </Txt>
                            </H>
                            <D>
                                <Txt size="s">9:00〜17:00</Txt>
                                <DTxt size="s" theme={muiTheme}>
                                    ※組数限定 完全予約制
                                </DTxt>
                            </D>
                        </Row>
                    </Table>
                    <MeritWrapper>
                        <LeftWrapper>
                            <MeritTitleWrapper>
                                <NumberImageWrapper>
                                    <StaticImage
                                        src="../images/number/01.jpg"
                                        alt=""
                                    />
                                </NumberImageWrapper>
                                <H3>
                                    <MeritTitle>
                                        お急ぎでも安心！最短納期！
                                    </MeritTitle>
                                </H3>
                            </MeritTitleWrapper>
                            <FeatureImageWrapper>
                                <StaticImage
                                    src="../images/salon/merit-1.jpg"
                                    alt=""
                                />
                            </FeatureImageWrapper>
                        </LeftWrapper>
                        <MeritDescription size="s">
                            職人スタッフ直オーダーだから完全な新品製作も最短で１週間！通常納期も２週間前後！オーダー後にお待たせせずご自宅にお届け致します。
                        </MeritDescription>
                        <MeritDescription size="s">
                            ※１週間特急料金3,240円、一部型番や長期休日を挟む場合3週間前後かかる場合もございます。
                        </MeritDescription>
                    </MeritWrapper>
                    <MeritWrapper>
                        <RightWrapper>
                            <MeritTitleWrapper>
                                <NumberImageWrapper>
                                    <StaticImage
                                        src="../images/number/02.jpg"
                                        alt=""
                                    />
                                </NumberImageWrapper>
                                <H3>
                                    <MeritTitle>
                                        デザイン無限！お探しのリングをご用意
                                    </MeritTitle>
                                </H3>
                            </MeritTitleWrapper>
                            <FeatureImageWrapper>
                                <StaticImage
                                    src="../images/salon/merit-2.jpg"
                                    alt=""
                                />
                            </FeatureImageWrapper>
                        </RightWrapper>
                        <MeritDescription size="s">
                            100以上の既存デザインはございますが、現地職人にお探しの形状をご相談頂ければペア価格５万円～9万円でお探しのリングも作製可能です。原型の時点で試着もできるから安心！※エタニティやエンゲージ、その他複雑な形状はお見積りに加算の場合がございます。
                        </MeritDescription>
                    </MeritWrapper>
                    <MeritWrapper>
                        <LeftWrapper>
                            <MeritTitleWrapper>
                                <NumberImageWrapper>
                                    <StaticImage
                                        src="../images/number/03.jpg"
                                        alt=""
                                    />
                                </NumberImageWrapper>
                                <H3>
                                    <MeritTitle>
                                        気楽に使いやすい！表参道の試着ルーム
                                    </MeritTitle>
                                </H3>
                            </MeritTitleWrapper>
                            <FeatureImageWrapper>
                                <StaticImage
                                    src="../images/salon/merit-3.jpg"
                                    alt=""
                                />
                            </FeatureImageWrapper>
                        </LeftWrapper>
                        <MeritDescription size="s">
                            表参道・原宿エリアのショールームではお客様が気兼ねなくご試着できる様、スタッフはあえて席を外させて頂きます。ご不明点やご相談は都度ご案内させて頂きますが、お二人だけで自由にご検討できるお時間を大切にし、不要なセールス等は一切行いません。お二人のペースでゆっくりご覧頂けます。ご来社が難しいお客様にはご自宅で試着ができるサンプルリング無料配送も実施しています。
                        </MeritDescription>
                    </MeritWrapper>
                    <MeritWrapper>
                        <RightWrapper>
                            <MeritTitleWrapper>
                                <NumberImageWrapper>
                                    <StaticImage
                                        src="../images/number/04.jpg"
                                        alt=""
                                    />
                                </NumberImageWrapper>
                                <H3>
                                    <MeritTitle>
                                        楽しめる街！表参道・原宿エリア
                                    </MeritTitle>
                                </H3>
                            </MeritTitleWrapper>
                            <FeatureImageWrapper>
                                <StaticImage
                                    src="../images/salon/merit-4.jpg"
                                    alt=""
                                />
                            </FeatureImageWrapper>
                        </RightWrapper>
                        <MeritDescription size="s">
                            ランチやディナー、ショッピングなど、指輪選びの休日をより充実した1日にできるスポットがいっぱい！原宿竹下通りや表参道ヒルズに近いワークショップのMissPlatinumで、寄り道の相談をしながら終始和やかに指輪選びを楽しめます。近隣のおすすめスポットなども職人スタッフへお気軽にお尋ね下さい。
                        </MeritDescription>
                    </MeritWrapper>
                    <MapWrapper>
                        <Map />
                    </MapWrapper>
                    <ReservationWrapper>
                        <ReservationTxt>
                            <Txt>来社予約する</Txt>
                        </ReservationTxt>
                        <ReservationButtons>
                            <Grid container spacing={1}>
                                <Grid item sm={6} xs={6}>
                                    <ReservationButton
                                        href="https://docs.google.com/forms/d/e/1FAIpQLSddBsiF-EVR_w8jf3fukTnZ2Wep-Y5VueK9-k08NX2MEkljCg/viewform"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth
                                        onClick={goToReservation}
                                    >
                                        ご来社予約
                                    </ReservationButton>
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <ReservationButton
                                        href="/rental/"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth
                                    >
                                        試着リング無料配送
                                    </ReservationButton>
                                </Grid>
                            </Grid>
                        </ReservationButtons>
                    </ReservationWrapper>
                </Grid>
            </Grid>
        </Layout>
    )
}

const H1 = styled('h1')({
    margin: '8px 0 12px',
    display: 'flex',
    justifyContent: 'center',
})
const H2 = styled('h2')({
    margin: '32px 0 12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
})
const H3 = styled('h3')({
    margin: 0,
})
const Desc = styled('div')({
    display: 'flex',
    flexDirection: 'column',
})
const CampaignTxt = styled(Txt)({
    marginBottom: '8px',
})
const Table = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '340px',
    margin: '20px auto',
    border: '1px solid #aaaaaa',
    borderBottom: 'none',
})
const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #aaaaaa',
})
const H = styled('div')({
    width: '76px',
    padding: '8px',
    borderRight: '2px solid #aaaaaa',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    alignItems: 'center',
})
const D = styled('div')({
    flex: 1,
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
})
const DTxt = styled(Txt)(({ theme }) => ({
    color: theme.palette.text.secondary,
}))
const MeritWrapper = styled('section')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: '48px',
})
const MeritTitleWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
})
const MeritTitle = styled(Txt)({
    padding: '8px',
})
const NumberImageWrapper = styled('div')({
    width: '48px',
})
const FeatureImageWrapper = styled('div')({
    width: '90%',
})
const MeritDescription = styled(Txt)({
    padding: '8px',
})
const LeftWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
})
const RightWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
})
const MapWrapper = styled('div')({
    marginTop: '28px',
    width: '100%',
    padding: '8px',
})
const ReservationWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '8px 0 20px',
})
const ReservationTxt = styled('h2')({
    marginBottom: '20px',
    padding: '8px',
    width: '100%',
    borderBottom: '1px solid #aaaaaa',
})
const ReservationButtons = styled('div')({
    width: '340px',
})
const ReservationButton = styled(Button)({
    padding: '8px 15px',
})

export default SalonPage
